.gcImg {
    max-width: 100%;
    box-shadow: 8px 8px 8px -3px #848484;
}

.containerImages {
  padding-right: 0rem;

  @media only screen and (min-width: 1025px) {
    padding-right: 33rem;
  }
}