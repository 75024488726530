@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  @font-face {
    font-family: 'Gotham-Book';
    font-weight: 400;
    src: local('Gotham-Book'), url(./fonts/ufonts.com_gotham-book.ttf) format('truetype');
  }
}

@layer base {
    h1 {
      @apply text-3xl font-semibold;
    }

    h2 {
      @apply text-2xl font-semibold;
    }

    h3 {
      @apply text-xl font-semibold;
    }

    h4 {
      @apply text-lg font-semibold;
    }

    h5 {
      @apply text-base font-semibold italic;
    }

    h1, h2, h3, h4, h5 {
      font-family: "Gotham-Book";
    }
}

.breadcrumbs > ul > li {
  color: #848484;
  font-weight: 600;

}

.breadcrumbs > ul > li:last-child > a {
  background: #9ca3af40;
  padding: 2px 10px;
  border-radius: 7px;
}

.breadcrumbs {
  display: none;
}

.gogImg {
  width: 80%;
  height: 80%;
  box-shadow: 8px 8px 8px -3px #848484;
  margin: 0 auto;
}
.img-blackShadow {
  width: 80%;
  height: 80%;
  /* border: 5px solid black; */
  box-shadow: 5px 5px 10px #848484;;
  margin: 0 auto;
}
.img-physical-card {
  width: 30%;
  height: 30%;
  /* border: 5px solid black; */
  box-shadow: 5px 5px 10px #848484;;
}
.img-physical-card-sm {
  display :inline-block;
  /* border: 5px solid black; */
  box-shadow: 5px 5px 10px #848484;;
}
.feature-list {
  list-style:disc;
  margin-left:40px;
}
.margin-top-30 {
  margin-top: 30px !important;
}
@media only screen and (min-width: 768px) {
  .breadcrumbs {
    margin-left: 3rem;
    display: flex;
  }
}