
.navbar {
  background-color: #eff7fa;
}

#breadcrumbs-responsive {
    display: flex;
    margin-top: 0;
}

.logo {
    max-width: 100%;
}

ul.menu-horizontal {
    display: none;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):focus), 
.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):hover), 
.menu :where(li:not(.menu-title):not(:empty)) > :where(*:not(ul):active) {
    background-color: rgb(35, 56, 118);
    color: rgb(255, 255, 255);
    border-radius: 8px;
}

.submenu li {
    padding: 1px 0;
}

.submenu li .active {
    background-color: rgb(156, 163, 175, 1);
}

.menu .active {  
    background-color: rgb(35, 56, 118);
    color: white;
    padding: 0.5rem 1rem;
}

@media only screen and (min-width: 768px) {
    .logo {
        max-width: 45%;
        margin: 0 auto;
    }

    #breadcrumbs-responsive {
        display: none;
    }
}

@media only screen and (min-width: 1280px) {
    ul.menu-horizontal {
        display: flex;
    }
}